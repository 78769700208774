/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES'
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME'
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU'
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES'
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS'

export * from './menu/actions'
export * from './settings/actions'
export * from './auth/actions'
