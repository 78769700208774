import { all, call, fork, put, takeEvery, } from 'redux-saga/effects'
import { auth, } from '../../helpers/Firebase'
import { LOGIN_USER, LOGOUT_USER, } from '../actions'

import { loginUserError, loginUserSuccess, } from './actions'
import { actionAuthAdmin, actionGetProfile, } from '../../api/users/requests'

export function * watchLoginUser () {
	yield takeEvery(LOGIN_USER, loginWithEmailPassword)
}

// eslint-disable-next-line max-len
const loginWithEmailPasswordAsync = async (email, password) => await actionAuthAdmin({
	email,
	password,
})
	.then(authUser => authUser)
	.catch(error => error)

// eslint-disable-next-line max-len
const profileAsync = async (token) => await actionGetProfile(token)
	.then(authUser => authUser)
	.catch(error => error)

function * loginWithEmailPassword ({ payload, }) {
	const { email, password, } = payload.user
	const { history, } = payload
	try {
		const { token, message, } = yield call(loginWithEmailPasswordAsync, email, password)
		if (!message) {
			const { message, ...user } = yield call(profileAsync, token)
			if (!message) {
				localStorage.setItem('token', token)
				localStorage.setItem('user_id', user.id)
				localStorage.setItem('user', JSON.stringify(user))
				yield put(loginUserSuccess(user))
				history.push('/')
			} else {
				yield put(loginUserError(message))
			}
		} else {
			yield put(loginUserError(message))
		}
	} catch (error) {
		yield put(loginUserError(error))
	}
}

export function * watchLogoutUser () {
	yield takeEvery(LOGOUT_USER, logout)
}

const logoutAsync = async (history) => {
	await auth.signOut()
		.then(authUser => authUser)
		.catch(error => error)
	history.push('/')
}

function * logout ({ payload, }) {
	const { history, } = payload
	try {
		yield call(logoutAsync, history)
		localStorage.removeItem('user')
		localStorage.removeItem('user_id')
	} catch (error) {
	}
}

export default function * rootSaga () {
	yield all([
		fork(watchLoginUser),
		fork(watchLogoutUser),
	])
}
