/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768
export const defaultLocale = 'ru'
export const localeOptions = [
	{ id: 'en', name: 'English', direction: 'ltr', },
	{ id: 'ru', name: 'Russian', direction: 'ltr', },
]

export const localeSelects = [
	{ value: 'en', label: 'English', key: 'ltr', },
	{ value: 'ru', label: 'Russian', key: 'ltr', },
]

export const firebaseConfig = {
	apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
	authDomain: 'gogo-react-login.firebaseapp.com',
	databaseURL: 'https://gogo-react-login.firebaseio.com',
	projectId: 'gogo-react-login',
	storageBucket: 'gogo-react-login.appspot.com',
	messagingSenderId: '216495999563',
}

export const searchPath = '/pages/search'
export const servicePath = 'https://api.coloredstrategies.com'

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true
export const defaultColor = 'light.blue'
export const defaultDirection = 'ltr'
export const isDarkSwitchActive = true
export const themeColorStorageKey = '__theme_color'
export const themeRadiusStorageKey = '__theme_radius'
