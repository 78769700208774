/* @flow */
import { portLink } from '../utilits/envConst'
import { isPlainObject } from 'lodash'

const queryParams = (params) => {
	return Object.keys(params)
		.map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
		.join('&')
}

const getRequestParams = (url, params) => {
	const options = Object.assign({}, params)

	if (options.data && !options.body) {
		options.body = options.data
	}

	if (!!options.urlParams) {
		Object.keys(options.urlParams)
			.forEach((key) => {
				let value = options.urlParams[key]
				url = url.replace(`:${key}`, value)
			})
	}

	if (isPlainObject(options.body)) {
		options.body = JSON.stringify(options.body)
	}


	const headers = new Headers()

	if (!!options.headers) {
		Object.keys(options.headers)
			.forEach((key) => {
				headers.append(key, options.headers[key])
			})
		if (!options.headers['Content-Type'] && !options.isForm) {
			headers.append('Content-Type', 'application/json; charset=utf-8')
		}
		headers.append('Accept', !options.headers.Accept ? 'application/json; charset=utf-8' : options.headers.Accept)
	} else {
		if (!options.isForm) {
			headers.append('Content-Type', 'application/json; charset=utf-8')
		}
	}

	options.mode = 'cors'

	if (options.queryParams || options.qs) {
		url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(options.queryParams || options.qs)
	}

	return {
		options: Object.assign(options, {
			headers: headers,
		}),
		url: createUrl(url, params.port),
	}
}


const createUrl = (path, port) => {
	const BASE_URL = `${portLink}${port}`
	let url
	if (path[0] === '/') {
		url = [BASE_URL, path.substr(1)]
	} else {
		url = [BASE_URL, path]
	}
	return url.join('/')
}

const errorHandler = (error, reject) => {
	try {
		console.log(error)
		if (!error.type) error.type = 'error'
		if (!error.title) {
			if (error.type === 'error') {
				error.message = 'error'
			} else {
				error.message = 'success'
			}
		}
		if (!error.text) {
			if (error.type === 'error') {
				error.message = 'something_wrong'
			} else {
				error.message = 'success'
			}
		}
		error.code = 999
		reject(error)
	} catch (error) {
		reject({
			type: 'error',
			message: 'something_wrong',
		})
	}
}

const responseHandler = async (response: Response, resolve, reject) => {
	try {
		let res = await response.json()
		if (!response.ok) {
			if (!!res.code) {
				reject({
					code: res.code,
					message: res.messages,
				})
			} else {
				reject({
					code: response.status,
					message: res.data,
				})
			}
		} else {
			if (!!response && response.status >= 400) {
				if (!!res.code) {
					reject({
						code: res.code,
						message: res.message,
					})
				} else {
					reject({
						code: response.status,
						message: res.detail,
					})
				}
			} else {
				resolve(res)
			}
		}
	} catch (err) {
		console.log(err)
		errorHandler(err, reject)
	}
}

export default (options: any) => {
	return new Promise(async (resolve, reject) => {
		const data = getRequestParams(options.url, options)
		try {
			const response: Response = await fetch(data.url, data.options)
			await responseHandler(response, resolve, reject)
		} catch (err) {
			console.log(err)
			errorHandler(err, reject)
		}
	})
}
