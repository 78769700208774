export const envLink = process.env.NODE_ENV === 'development' ? '91.122.52.238' : '91.122.52.238'
export const portLink = (process.env.NODE_ENV === 'development' ? 'https:' : window.location.protocol) + (process.env.NODE_ENV === 'development'
	? '//admin.pugachev-auction.ru/' : `//${window.document.domain}/`)
// eslint-disable-next-line no-nested-ternary
export const wsLink = (process.env.NODE_ENV === 'development' ? 'wss:' : (window.location.protocol === 'https:' ? 'wss:' : 'ws:')) + (process.env.NODE_ENV === 'development'
	? '//admin.pugachev-auction.ru/' : `//${window.document.domain}/ws/`)

export const webRTCLink = (
	process.env.NODE_ENV === 'development' ? 'wss://admin.pugachev-auction.ru/8443/webrtc'
		: `wss://${window.document.domain}/webrtc`
)
