import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_ERROR,
	LOGOUT_USER,
} from '../actions'

const INIT_STATE = {
	userId: localStorage.getItem('user_id'),
	forgotUserMail: '',
	newPassword: '',
	resetPasswordCode: '',
	loading: false,
	error: '',
	user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case LOGIN_USER:
		return { ...state, loading: true, error: '', }
	case LOGIN_USER_SUCCESS:
		return { ...state, loading: false, userId: action.payload.id, user: {...action.payload}, error: '', }
	case LOGIN_USER_ERROR:
		return { ...state, loading: false, userId: '', error: action.payload.message, user: {}, }
	case LOGOUT_USER:
		return { ...state, userId: null, error: '', user: {}, }
	default: return { ...state, }
	}
}
